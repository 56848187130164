import React, { useState, useEffect } from "react";
import AddWorkerDialog from "./AddWorkerDialog";
import Tile from "../../common/Tile";
import GenericDialog from "../../common/GenericDialog";
import { Grid, TextField, Paper } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import {
  getDepartments,
  getProjects,
  addDepartment
} from "../../../services/administrationService";
import AddUserDialog from "./AddUserDialog";
import DepartmentsTable from "./DepartmentsTable";
import {
  getUsers,
  getRoles,
  getUsersWithRoles
} from "../../../services/userService";
import { toast } from "react-toastify";
import UsersWithRolesTable from "./UsersWithRolesTable";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

const PAGES = {
  USERS: "USERS",
  DEPARTMENTS: "DEPARTMENTS"
};

function Administration() {
  const [openDialog, setOpenDialog] = useState(null);
  const [openPage, setOpenPage] = useState(PAGES.USERS);
  const [departments, setDepartments] = useState([]);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [usersWithRoles, setUsersWithRoles] = useState([]);
  const [department, setDepartment] = useState("");

  const fetchDepartments = async () => {
    try {
      const departments = await getDepartments();
      setDepartments(departments);
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  const fetchProjects = async () => {
    try {
      const projects = await getProjects();
      setProjects(projects);
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  const fetchUsers = async () => {
    try {
      const users = await getUsers();
      setUsers(users);
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await getRoles();
      setRoles(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  const fetchUsersWithRoles = async () => {
    try {
      const response = await getUsersWithRoles();
      setUsersWithRoles(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  const handleAddDepartment = async () => {
    try {
      await addDepartment({ department });
      fetchDepartments();
      setDepartment("");
      setOpenDialog(null);
      toast.success("Dodano dział");
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  useEffect(() => {
    fetchDepartments();
    fetchProjects();
    fetchUsers();
    fetchRoles();
    fetchUsersWithRoles();
  }, []);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid container item spacing={2}>
        <Grid item>
          <Tile
            icon={<PersonAddIcon style={{ fontSize: "3em" }} />}
            text="UŻYTKOWNICY"
            onClick={() => setOpenPage(PAGES.USERS)}
            isActive={openPage === PAGES.USERS}
          />
        </Grid>
        <Grid item>
          <Tile
            icon={<PeopleAltIcon style={{ fontSize: "3em" }} />}
            text="DZIAŁY"
            onClick={() => setOpenPage(PAGES.DEPARTMENTS)}
            isActive={openPage === PAGES.DEPARTMENTS}
          />
        </Grid>
      </Grid>
      <Paper elevation={4} square style={{ padding: 15, margin: 10 }}>
        <Grid item container spacing={2} direction="column">
          <Grid container item spacing={2}>
            {openPage === PAGES.USERS && (
              <>
                <Grid item>
                  <Tile
                    icon={<AddToQueueIcon style={{ fontSize: "3em" }} />}
                    text="DODAJ UŻYTKOWNIKA"
                    onClick={() => setOpenDialog("DODAJ UŻYTKOWNIKA")}
                    isActive={openDialog === "DODAJ UŻYTKOWNIKA"}
                    style={{ padding: 10, minWidth: 170 }}
                  />
                </Grid>
                <Grid item>
                  <Tile
                    icon={<PersonAddIcon style={{ fontSize: "3em" }} />}
                    text="DODAJ PRACOWNIKA"
                    onClick={() => setOpenDialog("DODAJ PRACOWNIKA")}
                    isActive={openDialog === "DODAJ PRACOWNIKA"}
                    style={{ padding: 10, minWidth: 170 }}
                  />
                </Grid>
              </>
            )}
            {openPage === PAGES.DEPARTMENTS && (
              <Grid item>
                <Tile
                  icon={<AssignmentReturnIcon style={{ fontSize: "3em" }} />}
                  text="DODAJ DZIAŁ"
                  onClick={() => setOpenDialog("DODAJ DZIAŁ")}
                  isActive={openDialog === "DODAJ DZIAŁ"}
                  style={{ padding: 10, minWidth: 170 }}
                />
              </Grid>
            )}
          </Grid>

          <Grid item container>
            <Grid item xs={12}>
              {openPage === PAGES.USERS && (
                <UsersWithRolesTable usersWithRoles={usersWithRoles} />
              )}
              {openPage === PAGES.DEPARTMENTS && (
                <DepartmentsTable departments={departments} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <AddWorkerDialog
        open={openDialog === "DODAJ PRACOWNIKA"}
        setOpen={setOpenDialog}
        departments={departments}
        projects={projects}
        users={users}
        fetchUsersWithRoles={fetchUsersWithRoles}
      />
      <AddUserDialog
        open={openDialog === "DODAJ UŻYTKOWNIKA"}
        setOpen={setOpenDialog}
        fetchUsers={fetchUsers}
        fetchUsersWithRoles={fetchUsersWithRoles}
        roles={roles}
      />
      <GenericDialog
        open={openDialog === "DODAJ DZIAŁ"}
        handleClose={() => setOpenDialog(null)}
        handleConfirm={handleAddDepartment}
        title="Dodaj Dział"
        content={
          <TextField
            label="Nowy Dział"
            value={department}
            onChange={e => setDepartment(e.target.value)}
            fullWidth
          />
        }
      />
    </Grid>
  );
}

export default Administration;
